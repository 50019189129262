import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import './CookieBar.css';

function CookieBar() {

  const [cookies, setCookie] = useCookies();
  const [hideCookie, setHideCookie] = useState(false);

  useEffect(() => {
    if (cookies.cookieBar !== undefined && cookies.cookieBar !== 'undefined') {
      setHideCookie(true);
    }
  }, [cookies]);

  const hideCookieBar = () => {
    setCookie('cookieBar', 'hide', { path: '/' });
  };

  return (
    <React.Fragment>
      {!hideCookie ? (
        <div className='cookie-bar'>
          <div className="cookie-bar-content-cover">
            <div className='cookie-title'>
              <p>Cookies</p>
              <img width="24" height="24" src='/images/cookie.svg' alt='cookie icon' />
            </div>
            <div className='cookie-text'>Please, confirm cookies to get the best experience on our site.</div>
            <div className='cookies-btn-container'>
              <button className='cookie-accept' onClick={hideCookieBar}>Confirm</button>
              <Link to='/cookies/' target="_blank" className='cookie-more'>More</Link>
            </div>
          </div>
        </div>
      ) : (
        null
      )}
    </React.Fragment>
  );
}

export default CookieBar;
