import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname, state } = useLocation();

  useEffect(() => {
    setTimeout(scrollFromPage, 500);
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [pathname]);

  function scrollFromPage() {
    if (pathname === '/' && state !== null) {
      document.querySelector(`#${state.id}`).scrollIntoView();
    }
  }

  return null;
}
