import React, { useState, useEffect, createContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useVacancies } from '../data/customHooks/vacancyHooks';

export const PositionsContext = createContext();

export const PositionsProvider = props => {
  let location = useLocation();

  const { vacanciesList } = useVacancies();
  const [positionId, setPositionId] = useState('');
  const [teamsArr, setTeamsArr] = useState([]);
  const [vacancyDepartments, setVacancyDepartments] = useState([]);
  const [teamsObj, setTeamsObj] = useState({});

  const allTeamsList = [
    'Creative',
    'Development',
    'Management',
    'QA',
    'Digital Marketing',
    'Administration',
    'Analytics',
    'Customer service',
    'Finance',
    'HR',
    'IT',
    'Operation',
  ];

  useEffect(() => {
    const pathname = location.pathname.split('/');
    const urlId = pathname[pathname.length - 1];
    const url = pathname[1];

    if (positionId === '' && url === 'position') {
      setPositionId(urlId);
    }
  }, [positionId]);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let newArr = [];
    vacanciesList.forEach(el => {
      newArr.push(el.department);
    });
    setVacancyDepartments(newArr);
  }, [vacanciesList]);

  useEffect(() => {
    let arr = [];
    arr = Object.entries(teamsObj).sort(function (a, b) {
      return b[1] - a[1];
    });
    setTeamsArr(arr);
  }, [teamsObj]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let obj = {};
    for (let i = 0; i < vacancyDepartments.length; i++) {
      for (let j = 0; j < allTeamsList.length; j++) {
        if (vacancyDepartments[i] === allTeamsList[j]) {
          allTeamsList.forEach(el => {
            if (!obj.hasOwnProperty(el)) {
              obj[el] = 0;
            }
            if (vacancyDepartments[i] === el) {
              obj[el] += 1;
            }
          });
        }
      }
    }
    setTeamsObj(obj);
  }, [vacancyDepartments]);

  return (
    <PositionsContext.Provider value={{ positionId, setPositionId, teamsArr, setTeamsArr }}>
      {props.children}
    </PositionsContext.Provider>
  );
};
