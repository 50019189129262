import { useState, useEffect } from 'react';
import performSearch from '../vacancyData';

export const useVacancies = () => {
  const [error, setError] = useState(null);
  const [isDataLoading, setDataLoading] = useState(false);
  const [vacanciesList, setVacanciesList] = useState([]);

  useEffect(() => {
    if (vacanciesList.length === 0) {
      performSearch()
        .then(data => {
          const { message, code } = data;
          if (code !== '200' && message) throw Error(message);
          setError(null);
          setVacanciesList(data.vacancies);
        })
        .catch(setError)
        .finally(() => setDataLoading(false));
    }
  }, [vacanciesList]);

  return {
    isDataLoading,
    setDataLoading,
    error,
    vacanciesList,
  };
};
