import React, { useState, useEffect, Suspense, lazy, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import Loader from './components/animations/Loader';
import ScrollToTop from './components/ScrollToTop';
import CookieBar from './components/cookie_bar/CookieBar';
import { PositionsProvider } from "./contexts/PositionsContext";
import { OptionsProvider } from "./contexts/OptionsContext";

import './App.css';

const Home = lazy(() => import('./pages/Home'));
const News = lazy(() => import('./pages/News'));
const Article = lazy(() => import('./pages/Article'));
const Career = lazy(() => import('./pages/Career'));
const Position = lazy(() => import('./pages/Position'));
const SendCV = lazy(() => import('./pages/SendCV'));
const Terms = lazy(() => import('./pages/Terms'));
const Privacy = lazy(() => import('./pages/Privacy'));
const Cookie = lazy(() => import('./pages/Cookie'));
const Header = lazy(() => import('./components/Header'));
const NotFound = lazy(() => import('./pages/NotFound'));

function App() {
  const mainRef = useRef();
  const technologyRef = useRef();
  const cultureRef = useRef();
  const visionRef = useRef();
  const servicesRef = useRef();
  const partnerSlideRef = useRef();

  const navHeader = [
    {
      headerTitle: "Home",
      headerRef: mainRef,
      headerID: "main"
    },
    {
      headerTitle: "Technology",
      headerRef: technologyRef,
      headerID: "technology"
    },
    {
      headerTitle: "Culture",
      headerRef: cultureRef,
      headerID: "culture"
    },
    {
      headerTitle: "Vision",
      headerRef: visionRef,
      headerID: "vision"
    },
    {
      headerTitle: "Services",
      headerRef: servicesRef,
      headerID: "services"
    },
    {
      headerTitle: "Partner",
      headerRef: partnerSlideRef,
      headerID: "partner-slider"
    }
  ];

  const pages = [
    { path: '/', element: <Home mainRef={mainRef} technologyRef={technologyRef} cultureRef={cultureRef} visionRef={visionRef} servicesRef={servicesRef} partnerSlideRef={partnerSlideRef} /> },
    { path: '/news/', element: <News /> },
    { path: '/article/:id', element: <Article /> },
    { path: '/career/', element: <Career /> },
    { path: '/position/:id', element: <Position /> },
    { path: '/sendcv/', element: <SendCV /> },
    { path: '/terms/', element: <Terms /> },
    { path: '/privacy/', element: <Privacy /> },
    { path: '/cookies/', element: <Cookie /> },
    { path: '*', element: <NotFound /> },
  ];

  return (
    <Router>
      <Suspense fallback={<div className='loader-container'>
        <Loader />
      </div>}>
        <ScrollToTop />
        <Header navHeader={navHeader} />
        <div className='app'>
          <PositionsProvider>
            <OptionsProvider>
              <Routes>
                {pages.map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={route.element}
                  />
                ))}
              </Routes>
            </OptionsProvider>
          </PositionsProvider>
        </div>
        <CookieBar />
      </Suspense >
    </Router >
  );
}

export default withCookies(App);
