import React, { createContext, useState } from "react";

export const OptionsContext = createContext();

// This context provider is passed to any component requiring the context
export const OptionsProvider = ({ children }) => {
  let [teamSelected, setTeamSelected] = useState('all teams');
  let [citySelected, setCitySelected] = useState('all cities');
  let [countrySelected, setCountrySelected] = useState('all countries');

  return (
    <OptionsContext.Provider
      value={{
        teamSelected,
        setTeamSelected,
        citySelected,
        setCitySelected,
        countrySelected,
        setCountrySelected,
      }}
    >
      {children}
    </OptionsContext.Provider>
  );
};
