function getPositionDetails(positionId) {
  return `/api/?action=getVacancy&id=${positionId}`;
}
function getVacanciesList() {
  return '/api/?action=getVacanciesList';
}
function sendFormData(action, formData) {
  return `/api/mail.php?action=${action}&${formData}`;
}
function uploadCareerFile() {
  return '/api/mail.php?action=upload';
}

const sendCvEmail = 'marketing@smart-gravity.com';
const partnersEmail = 'marketing@smart-gravity.com';

export { getPositionDetails, getVacanciesList, sendFormData, uploadCareerFile, sendCvEmail, partnersEmail };

// function getPositionDetails(positionId) {
//   return `http://dev.smart-gravity.com/api/?action=getVacancy&id=${positionId}`;
// }
// function getVacanciesList() {
//   return 'https://dev.smart-gravity.com/api/?action=getVacanciesList';
// }
// function sendFormData(action, formData) {
//   return `https://dev.smart-gravity.com/api/mail.php?action=${action}&${formData}`;
// }
// function uploadCareerFile(action, formData) {
//   return 'https://dev.smart-gravity.com/api/mail.php?action=upload';
// }

// const sendCvEmail = 'olha@bulls-media-ua.com';
// const partnersEmail = 'olha@bulls-media-ua.com';
// // marketing@smart-gravity.com
// // test22com.mando@gmail.com

// export { getPositionDetails, getVacanciesList, sendFormData, uploadCareerFile, sendCvEmail, partnersEmail };
